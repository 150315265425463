section.about {
  padding: 6em 0 10em 0;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ul {
    li {
      text-transform: uppercase;
      margin-bottom: 1em;
      font-size: 1.1em;
      &:before {
        content: '>';
        margin-right: 0.5em;
        color: $primary;
      }
    }
  }
  .lu {
    font-size: 2em;
    color: $primary;
  }
  @media (max-width: 680px) {
    ul {
      li {
        font-size: 1em;
        margin-bottom: 1.5em;
      }
    }
  }
}
