.input {
  border: 2px solid $white;
  display: block;
  padding: .9em 1.3em;
  width: 100%;
  box-sizing: border-box;
  transition: border-color .2s ease;
  &:focus, &:active {
    outline: none;
    border-color: $primary;
  }
}
.input-transparent-white {
  background: transparent;
  color: $white;
  &::placeholder {
    color: $secondaryLight;
  }
}

.input-group {
  margin-bottom: 1.5em;
  &.input-group-half {
    display: flex;
    > div {
      width: 49%;
      margin-right: 2%;
      &:last-child {
        margin-right: 0;
      }
    }
    @media (max-width: 460px) {
      flex-direction: column;
      > div {
        width: 100%;
        &:first-child {
          margin-bottom: 1.5em;
        }
      }
    }
  }
  .error {
    color: $error;
    padding: .5em;
  }
}