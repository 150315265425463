section.fields {
  padding: 6em 0 10em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.accordion {
  width: 30em;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  > .item {
    .header {
      padding: 0.5em;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 0.25s ease;
      &:hover {
        color: $primary;
      }
    }
    .content {
      padding: 1em 0 2em 0;
      display: none;
      ul {
        list-style: disc;
        list-style-position: inside;
        > li {
          margin-bottom: 0.5em;
        }
      }
    }
    &.open {
      .header {
        background: $primary;
        color: $white;
      }
      .content {
      }
    }
  }
}
