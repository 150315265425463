html {
  font-size: 10px;
}
body {
  font-family: $font-text;
  font-size: 1.4rem;
  line-height: 1.4;
  @media (min-width: 1441px) {
    font-size: 1.6rem;
  }
  @media (min-width: 1600px) {
    font-size: 1.7rem;
  }
  @media (min-width: 1800px) {
    font-size: 1.8rem;
  }
}
a {
  text-decoration: none;
}
.container {
  width: 120rem;
  max-width: 94%;
  margin: 0 auto;
  &.team-container {
    width: 140rem;
  }
}
h2,
h3 {
  text-transform: uppercase;
  font-weight: 300;
}
h1 {
  font-size: 1.8em;
}
h2 {
  font-size: 1.8em;
}
h3 {
  font-size: 1.4em;
}
h4 {
  font-size: 1.1em;
  font-weight: 300;
}
.h-first {
  margin-top: 0 !important;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
img {
  max-width: 100%;
}
body.overflow {
  overflow: hidden;
}
hr {
  height: 0;
  border-bottom: 1px solid $white;
}
