section.team {
}
.members {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  > div {
    flex: 1;
    background: $greyExtraLight;
    img {
      width: 100%;
    }
  }

  a {
    color: $black;
    &:hover {
      text-decoration: underline;
    }
  }
  p,
  ul {
    font-size: 0.9em;
    margin: 0.5em 0 1em 0;
  }
  p {
    //text-align: justify;
  }
  h3 {
    font-size: 0.9em;
    font-weight: 700;
    margin-bottom: 0.2em;
    letter-spacing: 0.1em;
    min-height: 2.8em;
  }
  h4 {
    margin: 0 0 1.5em 0;
    color: $greyDark;
    font-size: 1em;
  }
  h5 {
    margin-top: 0;
    margin-bottom: 0.5em;
  }
  .content {
    padding: 1em 1em 0 1em;
  }
  @media (max-width: 1200px) and (min-width: 1100px) {
    h3 {
      font-size: 0.7em;
    }
    .content {
      padding: 1em 1em 0 1em;
    }
  }
  @media (max-width: 1100px) and (min-width: 992px) {
    h3 {
      font-size: 0.7em;
    }
    .content {
      padding: 1em 1em 0 1em;
    }
  }

  @media (max-width: 992px) {
    flex-wrap: wrap;
    > div {
      width: calc(50% - 1rem);
      flex: none;
      font-size: 1.2em;
    }
  }
  @media (max-width: 690px) {
    flex-wrap: wrap;
    > div {
      width: 100%;
      font-size: 1.2em;
    }
  }
}
