section.contact {
  background: $secondary;
  color: $white;
  .flex {
    display: flex;
    > .b1 {
      width: 22em;
      margin-right: 4em;
      a {
        color: $white;
      }
      ul {
        > li {
          margin-bottom: .5em;
        }
      }
    }
    > .b2 {
      width: calc(100% - 26em);
    }
  }
  @media (max-width: 690px) {
    .flex {
      flex-direction: column;
      > .b1 {
        width: 100%;
        margin-right: 0;
        margin-bottom: 4em;
      }
      > .b2 {
        width: 100%;
      }
    }
  }
  h3 {
    font-size: 1.2em;
    margin: 2em 0;
  }
  hr {
    margin: 2em 0;
  }
  .thanks {
    h3 {
      margin-bottom: 0;
    }
  }
}