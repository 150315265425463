@keyframes slideGrow {
  from {
    width: 0;
  }
  to {
    width: 15em;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-appear, .fade-enter {
  animation: fadeIn .25s ease forwards;
  &-active {
    opacity: 1;
  }
}
.fade-leave {
  animation: fadeOut .25s ease forwards;
  &-active {
    opacity: 0;
  }
}
.fade-out-appear, .fade-out-enter {
  display: block;
  opacity: 0;
  animation: fadeIn .25s ease forwards;
  &-active {
    opacity: 1;
  }
}
.fade-out-leave {
  display: none;
  &-active {
    display: none;
  }
}