section {
  position: relative;
  h2 {
    color: $primary;
  }
  .header {
    text-align: center;
  }
}
.section {
  padding: 5em 0;
}
.section-full {
  box-sizing: border-box;
  min-height: calc(100vh - #{$headerHeight});
  @media (max-width: 992px) {
    min-height: 0;
  }
}
.section-bg {
  @include bg-full;
}
.section-grey {
  background: $greyExtraLight;
}