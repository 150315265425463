@mixin bg-full(){
  background: no-repeat center;
  background-size: cover;
}

@mixin absolute-middle-y() {
  position: absolute;
  top: 50%;

  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin absolute-middle-xy() {
  position: absolute;
  top: 50%;
  left: 50%;

  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

@mixin absolute-full(){
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

@mixin clearfix(){
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}