section.banner {
  position: relative;
  min-height: 100vh;
  &.banner-white {
    .we {
      color: $white;
      border-color: $white;
    }
  }
  .we {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    height: 4em;
    width: 0;
    color: $secondary;
    border-top: 1px solid $secondary;
    border-bottom: 1px solid $secondary;
    overflow: hidden;
    animation: slideGrow .5s linear 1s forwards;
    transition: all .25s ease;
    h1 {
      white-space: nowrap;
      font-weight: 200;
      margin: 0;
      opacity: 0;
      animation: fadeIn .5s linear 1.1s forwards;
      text-transform: uppercase;
    }
  }
  .scroll {
    position: absolute;
    bottom: .75em;
    left: 50%;
    transform: translate3d(-50%,0,0);
    text-align: center;
    transition: transform .25s ease;
    .box {
      font-size: .9em;
      padding: .5em 1.5em;
      border: 1px solid $secondary;
      color: $secondary;
      text-transform: uppercase;
      margin-bottom: .5em;
    }
    img {
      transition: transform .25s ease;
      width: 1.5em;
      margin-bottom: .5em;
    }
    &:hover {
      transform: translate3d(-50%,5%,0);
      img {
        transform: translate3d(0,40%,0);
      }
    }
  }
  @media (max-width: 992px) {
    width: 100%;
    padding-bottom: 100%;
    min-height: 0;
  }
  @media (max-width: 680px) {
    padding-bottom: 110%;
  }
  @media (max-width: 460px) {
    padding-bottom: 157.777778%;
  }

}
.banner-slider {
  position: absolute;
  z-index: -1;
  .slide {
    min-height: 100vh;
    width: 100%;
    background: no-repeat center / cover;
    @media (max-width: 992px) {
      height: 100vw;
      min-height: 0;
    }
    @media (max-width: 680px) {
      height: 110vw;
    }
    @media (max-width: 460px) {
      height: 157.777778vw;
    }
  }
}