section.legal {
  padding: 6em 0 10em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.accordion-legal {
  width: 50em;
  max-width: 100%;
  margin: 0 auto;
  a {
    color: $primary;
    &:hover {
      text-decoration: underline;
    }
  }
  > .item {
    .header {
      padding: 0.5em;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 0.25s ease;
      &:hover {
        color: $primary;
      }
    }
    .content {
      padding: 1em 0 2em 0;
      display: none;
      text-align: justify;
      ul {
        list-style: disc;
        padding-left: 1em;
        > li {
          margin-bottom: 0.5em;
        }
      }
      ol {
        padding-left: 1em;
      }
      .highlight {
        border: 2px solid $primary;
        padding: 1em 2em;
        margin: 1em 0;
      }
    }
    &.open {
      .header {
        background: $secondaryLight;
        color: $white;
      }
      .content {
      }
    }
  }
}

.table-simple {
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;

  @media (max-width: 480px) {
    font-size: 0.9em;
  }

  tr {
    td,
    th {
      vertical-align: top;
      padding: 0.5em;
      &:last-child {
        padding-right: 0;
      }
      &:first-child {
        padding-left: 0;
      }
    }
    &:first-child {
      td,
      th {
        padding-top: 0;
      }
    }
    &:last-child {
      td,
      th {
        padding-bottom: 0;
      }
    }
  }
}
