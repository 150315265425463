.btn {
  cursor: pointer;
  padding: .8em 1.5em;
  font-size: .9em;
  line-height: 1;
  border: 2px solid transparent;
  display: inline-block;
  font-weight: 700;
  text-align: center;
  transition: all .25s ease;
  outline: none;
  &:focus, &:hover, &:active {
    outline: none;
  }
}

.btn-white {
  border-color: $white;
  background: $white;
  color: $secondary;
  &:hover {

    background: $secondary;
    border-color: $primary;
    color: $white;
  }
}