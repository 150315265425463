header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  transition: all .25s ease;
  &.scrolled {
    background: rgba($white,.8);
    a {
      color: $secondary;
    }
    .logo {
      img {
        opacity: 1;
      }
    }
    .menu-bars {
      > div {
        background: $secondary;

      }
    }
  }
  .flex {
    transition: all .25s ease;
    padding: 0 2em;
    height: $headerHeight;
    align-items: center;
    display: flex;
    > .b2 {
      margin-left: auto;
    }
  }
  .logo {
    display: block;
    height: 3em;
    transition: all .25s ease;
    margin-bottom: -.25em;
    position: relative;
    z-index: 1200;
    img {
      transition: all .25s ease;
      height: 3em;
      width: auto;
    }
  }
  @media (max-width: 992px){
    .flex {
      padding: 0 1em;
      height: $headerHeight - 1em !important;
    }
  }
}
.nav {
  display: flex;
  color: $primary;
  > div {
    margin-left: 2em;
    &:first-child {
      margin-left: 0;
    }
  }
  a {
    text-transform: uppercase;
    color: $secondary;
    font-weight: 500;
    &:hover, &.active {
      color: $primary;
    }
  }
  @media (max-width: 992px) {
    display: none;
  }
}
.menu-opener {
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
}
.menu-bars {
  width: 1.6em;
  height: 1.1em;
  position: relative;
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: auto;
    background: $white;
    width: 100%;
    height: 2px;
    transition: background-color .25s ease;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.mobile-menu {
  opacity: 0;
  pointer-events: none;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  transition: opacity .25s ease;
  z-index: 2000;
  display: flex;
  justify-content: center;

  &.open {
    opacity: 1;
    pointer-events: auto;
  }
  .center {
    text-align: center;
    padding: 3em 1em;
    margin: auto 0;
  }
  a {
    color: $secondary;
    &:hover {
      color: $primary;
    }
    &.btn:hover {
      color: $white;
    }
  }
  .close-menu {
    position: fixed;
    cursor: pointer;
    top: 1.8em;
    right: 1.8em;
    width: 1.5em;
    height: 1.5em;
  }
  .navigation {
    font-size: 1.4em;
    flex-direction: column;
    font-size: 1.2em;
    margin-bottom: 1em;
    > li {
      margin: .75em 0;
    }
  }
}